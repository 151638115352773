/* eslint-disable */


// Import Highway
import Highway from '@dogstudio/highway';

 // Import Transitions
import Fade from '../util/transitions/fade';

// Import Renderers
import DefaultRenderer from '../util/renderer/default-renderer';
import UIkit from 'uikit';
import Map from '../routes/map';

import '../routes/ajax';

$('.uk-slider-nav a').attr('target','_self');

export default {
  init() {
    Map.init();
    if ( ! String.prototype.getDecimals ) {
      String.prototype.getDecimals = function() {
        var num = this,
          match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if ( ! match ) {
          return 0;
        }
        return Math.max( 0, ( match[1] ? match[1].length : 0 ) - ( match[2] ? +match[2] : 0 ) );
      }
    }
    // Quantity "plus" and "minus" buttons
    $( document.body ).on( 'click', '.plus, .minus', function() {

      var $qty        = $( this ).closest( '.quantity' ).find( '.qty'),
        currentVal  = parseFloat( $qty.val() ),
        max         = parseFloat( $qty.attr( 'max' ) ),
        min         = parseFloat( $qty.attr( 'min' ) ),
        step        = $qty.attr( 'step' );
      // Format values
      if ( ! currentVal || currentVal === '' || currentVal === 'NaN' ) currentVal = 0;
      if ( max === '' || max === 'NaN' ) max = '';
      if ( min === '' || min === 'NaN' ) min = 0;
      if ( step === 'any' || step === '' || step === undefined || parseFloat( step ) === 'NaN' ) step = 1;

      // Change the value
      if ( $( this ).is( '.plus' ) ) {
        if ( max && ( currentVal >= max ) ) {
          $qty.val( max );
        } else {
          $qty.val( ( currentVal + parseFloat( step )).toFixed( step.getDecimals() ) );
        }
      } else {
        if ( min && ( currentVal <= min ) ) {
          $qty.val( min );
        } else if ( currentVal > 0 ) {
          $qty.val( ( currentVal - parseFloat( step )).toFixed( step.getDecimals() ) );
        }
      }

      // Trigger change event
      $qty.trigger( 'change' );
    });


    // $(document.body).on( 'click', '.vy_deces_voeux_listing_pager a.page-numbers', function() {
    //   $([document.documentElement, document.body]).animate({
    //       scrollTop: $(".vy_deces_voeux").offset().top
    //   }, 2000);
    // });

    $(document.body).on( 'click', '.vy_archive_listing_pager a.page-numbers', function() {
      $([document.documentElement, document.body]).animate({
          scrollTop: $(".vy_archive_deces").offset().top
      }, 2000);
    });

    $(document).ready(function(){
      $('.vy_voeux_tab .frm_radio').first().css({"background-color": "white", "color": "#8EBBAB"});
      $(document.body).on( 'click', '.vy_voeux_tab .frm_radio input', function() {
        $('.frm_radio').css({"background-color": "#8EBBAB", "color": "white"});
        $(this).parents('.frm_radio').css({"background-color": "white", "color": "#8EBBAB"});
      });
    });

    // $('.message_propose option').each(function() {
    //   if($(this).is(':selected')){
    //     $('.message_sympathie textarea').val($(this).val());
    //     alert('yeahyeah');
    //   }
    // });

    $(document).ready(function(){
      if ($("section").hasClass("vy_voeux_print")){
        // $('#printf')[0].contentWindow.print()
        window.print()
        return false;
      }
    //   console.log('test 2');

    //   var buttons = $('.vy_deces_details_diffusion_bouton');
    // console.log('Number of buttons found:', buttons.length);
    if ($('#funeraweb[data-webdiffusionid]').length || $('#funeraweb[data-deceasedid]').length) {
		console.log('funeraweb already loaded');
	
		var dataId = $('#funeraweb[data-webdiffusionid]').length ? 'webdiffusionid' : 'deceasedid';
		var idValue = $('#funeraweb[data-' + dataId + ']').data(dataId);
		var tabfuneraweb = $('#funeraweb[data-tabfuneraweb]').data('tabfuneraweb');
	
		const options = {
			framework: 'uikit',
			tab: tabfuneraweb,
			rootClass: 'uk-container'
		};
		options[dataId === 'webdiffusionid' ? 'webcastId' : 'deceasedId'] = idValue;
	
		const ref = eMemorialLoad('#funeraweb', options);
	
		$(document).on('click', '.ememorial-app-root .vy_deces_details_diffusion_bouton.--diffusion', function (e) {
			ref.openTab('webcast');
			// obituary, webcast, guestbook, viewing, interment, store, static-viewing, resting-place, tribute
			e.preventDefault();
		});
	}
	

      // $(document).on('click', '.switcher-root.switcher a[href*="diffusions"]', function(e) {
      //   console.log('Switcher button clicked:', $(this));
      //   // Your custom logic for the switcher button click event
      // });
    });

    $('.js_btn_application').each(function(){
      $(this).click(function(){
        // console.log($(this).attr("data-job"));
        $('#field_job').val($(this).attr("data-job"))
      })
    });

    // JavaScript to be fired on all pages
    $(".vy_link_cover").hover(
      function () {
        // console.log('hover');
        $(this).parent().find(".uk-button").addClass('uk-active');
        $(this).parent().addClass('uk-transition-active uk-active');
      }, function () {
        // console.log('unhover');
        $(this).parent().find(".uk-button").removeClass('uk-active');
        $(this).parent().removeClass('uk-transition-active uk-active');
      }
    )
    $( ".uk-offcanvas-bar a" ).unbind('click').on( "click",function(){
      //.uk-offcanvas-bar
      UIkit.offcanvas('.uk-offcanvas.uk-open').hide();
    });


    $(function() {

      $('.fb-share').click(function(e) {
        e.preventDefault();
        window.open($(this).attr('href'), 'fbShareWindow',
          'height=450, width=550, top=' + ($(window).height() / 2 - 275) +
          ', left=' + ($(window).width() / 2 - 225) +
          ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        return false;
      });

      if ( $( "#vy_header_global_alert" ).length ) {
        var globalAlertSeen = parseInt(sessionStorage.getItem("globalAlertSeen"));
        var global_alert = $('#vy_header_global_alert');

        console.log(globalAlertSeen);
        console.log(global_alert);


        if(globalAlertSeen != true){
          console.log('in');
          global_alert.removeClass('uk-hidden');
        }else{

          UIkit.alert(global_alert).close();
        }
        $('.vy_header_global_alert_close').click(function(){
          if (window.sessionStorage) {
            var dropselvalue = 1;
            sessionStorage.setItem("globalAlertSeen", dropselvalue);
            globalAlertSeen = parseInt(sessionStorage.getItem("globalAlertSeen"));
          }
        });
      }
      if ( $( "#vy_popup" ).length ) {
        var globalPopupSeen = parseInt(sessionStorage.getItem("globalPopupSeen"));
        var global_popup = $('#vy_popup');

        if(globalPopupSeen != true){
          UIkit.modal(global_popup).show();
        }
        $('.vy_popup_close').click(function(){
          if (window.sessionStorage) {
            var dropselvalue = 1;
            sessionStorage.setItem("globalPopupSeen", dropselvalue);
            globalPopupSeen = parseInt(sessionStorage.getItem("globalPopupSeen"));
          }
        });
      }


      UIkit.util.on('.vy_navbar_ecom_search_drop', 'show', function () {
        $('.vy_main_content').toggleClass('uk-active');
      });
      UIkit.util.on('.vy_navbar_ecom_search_drop', 'hide', function () {
        $('.vy_main_content').toggleClass('uk-active');
      });

      if($('.vy_banner_slideshow').length){
        UIkit.util.on('.vy_banner_slideshow > .uk-slideshow', 'itemshown', function () {
          if($('.vy_banner_slideshow_wrapper > .uk-active .vy_banner_slideshow_content_wrapper').hasClass('uk-light')){
            $('.vy_banner_slideshow_dotnav').addClass('uk-light');
          }else{
            $('.vy_banner_slideshow_dotnav').removeClass('uk-light');
          }
        });
      }
    });




    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

  },
};

// Get IE or Edge browser version
var version = detectIE();
var using_IE = false;

if (version === false) {
  console.log('IE/Edge');
} else if (version >= 12) {
  console.log('Edge ' + version);
} else {
  console.log('IE ' + version);
  using_IE = true;

  setTimeout(function(){
    $('.home .vy_loading').fadeOut();
  }, 4000);
}
// add details to debug result
console.log (window.navigator.userAgent);

// Call Highway.Core once.
if($('.transition').length != 0  && using_IE == false){
  const H = new Highway.Core({
    transitions: {
      default: Fade
    },
    renderers: {
      name: DefaultRenderer,
      //home: TimeOut
    }
  });
  H.on('NAVIGATE_END', ({ location }) => {
    // Check Anchor
    if (location.anchor) {
      // Get element
      const el = document.querySelector(location.anchor);

      if (el) {
        // Scroll to element
        window.scrollTo(el.offsetLeft, el.offsetTop);

      }
    }
  });
}




function fbShare(url, title, descr, image, winWidth, winHeight) {
  var winTop = (screen.height / 2) - (winHeight / 2);
  var winLeft = (screen.width / 2) - (winWidth / 2);
  window.open('http://www.facebook.com/sharer.php?s=100&p[title]=' + title + '&p[summary]=' + descr + '&p[url]=' + url + '&p[images][0]=' + image, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+winWidth+',height='+winHeight);
}


function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}
