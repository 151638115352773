/* eslint-disable */

var isMapsApiLoaded = false;
var isPreparMap = false;
var map;
var markers = [];
if(typeof vy_google !== 'undefined'){
  var pin_default = vy_google.pin_image;
  var pin_cluster = vy_google.pin_cluster_image;
}
var infowindow;

import { MarkerClusterer, GridAlgorithm } from "@googlemaps/markerclusterer";




export default {
	init() {
    // console.log('initmap');

    // JavaScript to be fired on all pages
    if (document.getElementsByClassName('vy_google_map').length > 0 && !isMapsApiLoaded) {
      window.onload = loadScript('preparMap');
    }else if(document.getElementsByClassName('vy_google_map').length > 0 && typeof google === undefined && typeof google.maps === undefined){
      window.onload = loadScript('preparMap');
    }else if(document.getElementsByClassName('vy_google_map').length > 0){
      preparMap();
    }
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
function initMap(mapinfo) {

  var myLat = mapinfo.datalat;
  var myLng = mapinfo.datalng;
  var myLatLng = { lat: mapinfo.datalat, lng: mapinfo.datalng };

  var center_map = '';
  if (mapinfo.center) {
    center_map = mapinfo.center;
  } else {
    center_map = myLatLng;
  }

  var styles=[{"stylers":[{"saturation":-100}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#9acddc"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#d9b266"}]},{"featureType":"road.highway","elementType":"labels.icon","stylers":[{"saturation":71},{"hue":"#ffbb00"}]},{"featureType":"poi","stylers":[{"visibility":"off"}]},{}];
  //console.log(center_map);

  var map = new google.maps.Map(document.getElementById(mapinfo.id), {
    zoom: mapinfo.zoom,
    disableDefaultUI: mapinfo.disableDefaultUI,
    draggable: mapinfo.draggable,
    draggableCursor: mapinfo.draggableCursor,
    mapTypeId: mapinfo.maptype,
    center: center_map,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: true,
    scrollwheel: false,
    styles: styles,
  });
  if (mapinfo.disableDefaultUI == false) {
    if (mapinfo.pin == 'default') {
			var image = pin_default;
		}else{
      var image = mapinfo.pin;
    }
    if (mapinfo.pin_cluster == 'default') {
			var pin_cluster_image = pin_cluster;
		}else{
      var pin_cluster_image = mapinfo.pin_cluster;
    }

    var bounds = new google.maps.LatLngBounds();
    var infowindow = new google.maps.InfoWindow();

    var marker, i;

    if (mapinfo.multiple_markers) {
      var markers_object = $(mapinfo.multiple_markers);

      markers_object.each(function () {

        marker = new google.maps.Marker({
          position: new google.maps.LatLng($(this).data('lat'), $(this).data('lng')),
          map: map,
          icon: image
        });

        //extend the bounds to include each marker's position
        bounds.extend(marker.position);
        markers.push(marker);

        var content_title = '';
        var content_address = '';
        var content_phone = '';
        var tmp_lat = $(this).data('lat')
        var tmp_lng = $(this).data('lng')

        if ($(this).attr('data-title')) {
          content_title = $(this).data('title');
        }
        if ($(this).attr('data-address')) {
          content_address = $(this).data('address');
        }
        if ($(this).attr('data-phone')) {
          content_phone = $(this).data('phone');
        }

        if(content_title != '' || content_address != '' || content_phone != ''){
          var content = '<div class="vy_infowindow">';
          if(content_title != '') content += '<h4 class="vy_infowindow_title">'+content_title+'</h4>';
          if(content_address != '') content += '<p class="vy_infowindow_description">'+content_address+'</p>';
          if(content_phone != '') content += '<p class="vy_infowindow_phone">'+content_phone+'</p>';

          content +='<a target="_blank" href="https://maps.google.com/?daddr=' + tmp_lat + ',' + tmp_lng +'" class="vy_infowindow_link uk-button uk-button-primary uk-button-small">' +
          '<span>Voir la carte </span>' +
          '<span class="uk-icon" data-uk-icon="chevron-right"></span>'
          "</a>";

          google.maps.event.addListener(marker,'click', (function(marker,content,infowindow){
            return function() {
                infowindow.setContent(content);
                infowindow.open(map,marker);
            };
          })(marker,content,infowindow));
        }else{
          google.maps.event.addListener(marker, 'click', function () {
            window.open('https://maps.google.com/?daddr=' + tmp_lat + ',' + tmp_lng);
          });
        }
      });

      
      const renderer = {
        render({ count, position }) {
            return new google.maps.Marker({
                label: { text: String(count), color: "white", fontSize: "20px" },
                icon: {
                    url: pin_cluster_image,
                    scaledSize: new google.maps.Size(44, 44),
                },
                position,
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
            });
        }
    }

    const gridOptions = {
        gridSize: 44,
        maxDistance: 400,
        maxZoom: 12,
        viewportPadding: 60
    };

    new MarkerClusterer({
        map,
        markers,
        renderer,
        algorithm: new GridAlgorithm(gridOptions)
    });
      //now fit the map to the newly inclusive bounds
      map.fitBounds(bounds);
    }else{
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(myLat, myLng),
        map: map,
        icon: image,
      });

      google.maps.event.addListener(marker, 'click', (function (marker, i) {
        return function () {
          window.open('https://maps.google.com/?daddr=' + myLat + ',' + myLng);
        }
      })(marker, i));
    }
  }
}

window.preparMap = function () {
  //bounds = new google.maps.LatLngBounds();
  //infowindow = new google.maps.InfoWindow();
  isMapsApiLoaded = true;
  var datalat = '';
  var datalng = '';
  var id = '';
  var disableDefaultUI = false;
  var draggable = true;
  var draggableCursor = '';
  var zoom = 10;
  var marker = 'default';
  var pin = 'default';
  var pin_cluster = 'default';
  var maptype = 'roadmap';
  var center = '';
  var multiple_markers = '';
  $('.vy_google_map').each(function () {
    datalat = $(this).data('lat');
    datalng = $(this).data('lng');
    // console.log(datalat[0].value);
    // console.log(datalng);

    if ($(this).attr('data-pin')) {
      pin = $(this).data('pin');
    }
    if ($(this).attr('data-pin-cluster')) {
      pin_cluster = $(this).data('pin-cluster');
    }
    if ($(this).attr('data-zoom')) {
      zoom = parseInt($(this).data('zoom'));
    }
    if ($(this).attr('data-center-lat') && $(this).attr('data-center-lng')) {
      center = { lat: $(this).data('center-lat'), lng: $(this).data('center-lng') };
    }
    if ($(this).attr('data-marker')) {
      marker = $(this).data('marker');
    }
    if ($(this).attr('data-maptype')) {
      maptype = $(this).data('maptype');
    }
    id = $(this).attr('id');
    if ($(this).data('disableui') == true) {
      disableDefaultUI = $(this).data('disableui');
      draggable = false;
      draggableCursor = 'default';
    }

    if ($(this).attr('data-multiple-marker')) {
			multiple_markers = $(this).data('multiple-marker');
		}

    // console.log('---vy_google_map---');
    // console.log('ID: ' + id);
    // console.log('LAT: ' + datalat);
    // console.log('LNG: ' + datalng);
    // console.log('center: '); console.dir(center);
    // console.log('zoom: ' + zoom);
    // console.log('disableDefaultUI: ' + disableDefaultUI);
    // console.log('draggable: ' + draggable);
    // console.log('draggableCursor: ' + draggableCursor);
    // console.log('marker: ' + marker);
    // console.log('pin: ' + pin);
    // console.log('pin cluster: ' + pin_cluster);
    // console.log('multiple_markers: ' + multiple_markers);
    var mapinfo = { datalat: datalat, datalng: datalng, id: id, zoom: zoom, disableDefaultUI: disableDefaultUI, draggable: draggable, draggableCursor: draggableCursor, marker: marker, center: center, maptype: maptype, pin: pin,pin_cluster:pin_cluster,multiple_markers: multiple_markers };
    initMap(mapinfo);
  });
}

function loadScript(callback) {
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
    '&callback=' + callback + '&key='+vy_google.key;
  document.head.appendChild(script);
}

// console.log('initbottom');
